import React from "react";
import {Row, Col,} from "reactstrap";

const Header = () => {
    return <Row className="H__row">
        <Col className="H__col">
            <Row>
                <Col sm="6" className="H__text">
                        <h1>Witam! </h1>
                        <hp>W życiu kieruję się zasadą "wygrany-wygrany".</hp>
                        <p> Zmiana to szansa. Stagnacja to pewność porażki.</p>
                        <h1> Wojciech Baryga</h1>
                </Col>
            </Row>
        </Col>
    </Row>

}

export default Header