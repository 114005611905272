import React from "react"
import Layout from "../components/layout";
import Header from "../components/header"

const IndexPage = () => {

    return <Layout>
        <Header/>
    </Layout>
}

export default IndexPage


